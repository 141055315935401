import { createSlice } from '@reduxjs/toolkit';

const commonSlice = createSlice({
    name: 'common',
    initialState: {
        enableAsset: false,
        profile: false
    },
    reducers: {
        SetAsset: (state, param) => {
            const { payload } = param;
            state.enableAsset = payload;
        },

        SetProfile: (state, param) => {
            const { payload } = param;
            state.profile = payload;
        },
    },
});

const { actions, reducer } = commonSlice;
export const { SetAsset, SetProfile } = actions;
export default reducer;

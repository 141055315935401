import { configureStore } from '@reduxjs/toolkit';
import authReducer from './AuthSlice';
import commonReducer from './CommonSlice';
import securityReducer from './SecuritySlice';
import factorReducer from './FactorSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        common: commonReducer,
        security: securityReducer,
        factor: factorReducer
    },
});

export default store;

import React,{useEffect} from "react";
import "./App.css";
import Button from '@mui/material/Button';
import { SnackbarProvider } from 'notistack';
import { useRoutes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Themeroutes from './Routes/Router';
import { saveAuthInfo } from "./Store/AuthSlice";

function App() {
  const snackbarRef = React.createRef();
  const dispatch = useDispatch();
  const onClickDismiss = (key) => () => {
    snackbarRef.current.closeSnackbar(key);
  };

  useEffect(() => {
    try {
      const localUser = localStorage.getItem('user');
      if (localUser && localUser.length) {
        dispatch(saveAuthInfo(JSON.parse(localUser)));
      }
    } catch (err) {
      console.error('Error to parse----------------', err.stack);
    }
  }, [dispatch]);
  const routing = useRoutes(Themeroutes);

  return (
    <div>
      <SnackbarProvider
        maxSnack={3}
        ref={snackbarRef}
        action={(key) => (
          <Button
            sx={{ color: "#fff" }}
            size="small"
            onClick={onClickDismiss(key)}
          >
            Dismiss
          </Button>
        )}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <div>
          {routing}
        </div>
      </SnackbarProvider>
    </div>
  );
}

export default App;

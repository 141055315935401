import api from "../../common/api"

const getHeaders = () => ({
  "authorization": localStorage.getItem("token"),
  "Content-Type": "application/json",
  username: localStorage.getItem("username"),
});

let baseURL = process.env.REACT_APP_BASE_URL;

// if (process.env.NODE_ENV.trim() === 'production' || process.env.NODE_ENV.trim() === 'prod') {
//   baseURL = 'https://tech.orim.in/api';
// } else {
//   baseURL = 'https://orim.districtd.in/api';
// }
export function orimRiskScore({ method, endpoint, params, data, headers }) {
  return new Promise((resolve, reject) => {
    api.request({
      method,
      baseURL,
      url: endpoint,
      headers: headers || getHeaders(),
      params,
      data,
    })
      .then((resp) => {
        const data = resp.data || {};
        if (data.success === true || data.error.code === 0) {
          resolve(data);
        } else if (
          data.success === "error" ||
          data.error.code === 1 ||
          data.success === false
        ) {
          reject(data.message || "error");
        } else {
          reject("Invalid response");
        }
      })
      .catch((err) => {
        const errorResponse = (err && err.response) || {};
        if (errorResponse.status === 401) {
          reject(errorResponse.statusText);
          localStorage.removeItem("token");
          window.location.reload();
          return;
        }
        if (errorResponse.data && errorResponse.data.message) {
          reject(errorResponse.data.message);
        } else {
          reject(errorResponse.statusText);
        }
      });
  });
}

export function orimdumperAPI({
  method,
  endpoint,
  params,
  data,
  headers,
  // bypassUnauthorizedCheck = false,
}) {
  return new Promise((resolve, reject) => {
    api.request({
      method,
      baseURL,
      url: endpoint,
      headers: headers || getHeaders(),
      params,
      data,
    })
      .then((resp) => {
        const data = resp.data || {};
        if (data.success === true) {
          resolve(data);
        } else if (data.success === false) {
          reject(data.message || "error");
        } else {
          reject("Invalid response");
        }
      })
      .catch((err) => {
        const errorResponse = (err && err.response) || {};
        if (errorResponse.status === 401) {
          reject(errorResponse.statusText);
          localStorage.removeItem("token");
          window.location.reload();
          return;
        }
        if (errorResponse.data && errorResponse.data.message) {
          reject(errorResponse.data.message);
        } else {
          reject(errorResponse.statusText);
        }
      });
  });
}

export function orimdumperAPITwo({
  method,
  endpoint,
  params,
  data,
  headers,
  // bypassUnauthorizedCheck = false,
}) {
  return new Promise((resolve, reject) => {
    api.request({
      method,
      baseURL,
      url: endpoint,
      headers: headers || getHeaders(),
      params,
      data,
    })
      .then((resp) => {
        const data = resp.data || {};
        if (data.success === true) {
          resolve(data);
        } else if (data.success === false) {
          reject(data.message || "error");
        } else {
          reject("Invalid response");
        }
      })
      .catch((err) => {
        console.log(err);
        const errorResponse = (err && err.response) || {};
        if (errorResponse.status === 401) {
          reject(errorResponse.statusText);
          localStorage.removeItem("token");
          window.location.reload();
          return;
        }
        if (errorResponse.data && errorResponse.data.message) {
          reject(errorResponse.data.message);
        } else {
          reject(errorResponse.statusText);
        }
      });
  });
}

export function  orimdumperSignAPI({
  method,
  endpoint,
  params,
  data,
  headers,
  // bypassUnauthorizedCheck = false,
}) {
  return new Promise((resolve, reject) => {
   api.request({
      method,
      baseURL,
      url: endpoint,
      headers: headers || getHeaders(),
      params,
      data,
    })
      .then((resp) => {
        const data = resp.data;
        
        resolve(data);
      })
      .catch((err) => {
        const errorResponse = (err && err.response) || {};
        if (errorResponse.status) {
          reject(errorResponse.data.message);
          localStorage.removeItem("token");
          // window.location.reload();
          return;
        }
         else {
          reject(errorResponse.message);
        }
      });
  });
}

export function orimInvestmentDetailsTwo({
  method,
  endpoint,
  params,
  data,
  headers,
  bypassUnauthorizedCheck = false,
}) {
  return new Promise((resolve, reject) => {
    api.request({
      method,
       baseURL,
      url: endpoint,
      headers: headers || getHeaders(),
      params,
      data,
    })
      .then((resp) => {
        const data = resp.data || {};
        if (
          data.success === true ||
          data.error.code === 0 ||
          data.status === "Success"
        ) {
          resolve(data);
        } else if (
          data.status === "error" ||
          data.error.code === 1 ||
          data.success === false
        ) {
          reject(data.message || "error");
        } else {
          reject("Invalid response");
        }
      })
      .catch((err) => {
        const errorResponse = (err && err.response) || {};
        if (!bypassUnauthorizedCheck && errorResponse.status === 401) {
          reject(errorResponse.statusText);
          localStorage.removeItem("token");
          window.location.reload();
          return;
        }
        if (errorResponse.data && errorResponse.data.message) {
          reject(errorResponse.data.message);
        } else {
          reject(errorResponse.statusText);
        }
      });
  });
}

export function fileUpload({ method, endpoint, params, data, headers }) {
  return new Promise((resolve, reject) => {
    api.request({
      method,
      baseURL,
      url: endpoint,
      headers: headers || getHeaders(),
      params,
      data,
    })
      .then((resp) => {
        const data = resp.data || {};
        if (data.success === true) {
          resolve(data);
        } else if (
          data.success === "error" ||
          data.success === false
        ) {
          reject(data.message || "error");
        } else {
          reject("Invalid response");
        }
      })
      .catch((err) => {
        const errorResponse = (err && err.response) || {};
        if (errorResponse.status === 401) {
          reject(errorResponse.statusText);
          return;
        }
        if (errorResponse.data && errorResponse.data.message) {
          reject(errorResponse.data.message);
        } else {
          reject(errorResponse.statusText);
        }
      });
  });
}

export function getRiskProfiler({ method, endpoint, params, data, headers }) {
  return new Promise((resolve, reject) => {
    api.request({
      method,
      baseURL,
      url: endpoint,
      headers: headers || getHeaders(),
      params,
      data,
    })
      .then((resp) => {
        const data = resp.data || {};
        if (data.success === true) {
          resolve(data);
        } else if (
          data.success === "error" ||
          data.success === false
        ) {
          reject(data.message || "error");
        } else {
          reject("Invalid response");
        }
      })
      .catch((err) => {
        const errorResponse = (err && err.response) || {};
        if (errorResponse.status === 401) {
          reject(errorResponse.statusText);
          return;
        }
        if (errorResponse.data && errorResponse.data.message) {
          reject(errorResponse.data.message);
        } else {
          reject(errorResponse.statusText);
        }
      });
  });
}

export function  orimUserPermission({
  method,
  endpoint,
  params,
  data,
  headers,
  // bypassUnauthorizedCheck = false,
}) {
  return new Promise((resolve, reject) => {
   api.request({
      method,
      baseURL,
      url: endpoint,
      headers: headers || getHeaders(),
      params,
      data,
    })
      .then((resp) => {
        const data = resp.data;
        resolve(data);
      })
      .catch((err) => {
        const errorResponse = (err && err.response) || {};
        if (errorResponse.status) {
          reject(errorResponse.data.message);
          return;
        }
         else {
          reject(errorResponse.message);
        }
      });
  });
}

export function orimFetchStrategiesAPI({
  method,
  endpoint,
  params,
  data,
  headers,
  // bypassUnauthorizedCheck = false,
}) {
  return new Promise((resolve, reject) => {
    api.request({
      method,
      baseURL,
      url: endpoint,
      headers: headers || getHeaders(),
      params,
      data,
    })
      .then((resp) => {
        const data = resp.data;
        resolve(data);
      })
      .catch((err) => {
        const errorResponse = (err && err.response) || {};
        if (errorResponse.status) {
          reject(errorResponse.data.message);
          return;
        }
        else {
          reject(errorResponse.message);
        }
      });
  });
}


export function saveStrategy({
  method, endpoint, params, data, headers
}){
  return new Promise((resolve, reject) => {
    api.request({
      method,
      baseURL,
      url: endpoint,
      headers: headers || getHeaders(),
      params,
      data,
    })
      .then((resp) => {
        const data = resp.data || {};
        if (data) {
          resolve(data);
        } else if (
          data.success === "error" ||
          data.success === false
        ) {
          reject(data.message || "error");
        } else {
          reject("Invalid response");
        }
      })
      .catch((err) => {
        const errorResponse = (err && err.response) || {};
        if (errorResponse.status === 401) {
          reject(errorResponse.statusText);
          return;
        }
        if (errorResponse.data && errorResponse.data.message) {
          reject(errorResponse.data.message);
        } else {
          reject(errorResponse.statusText);
        }
      });
  });

}
// export function StrategyView(){
//   method, endpoint, params, data, headers
// }

import { createTheme } from "@mui/material/styles";

export const dashboardThemes = createTheme({
  typography: {
    fontFamily: "Lato",
    h4: {
      fontSize: "20px",
      fontWeight: 800,
    },
    h5: {
      fontSize: "15px",
      fontWeight: 600,
    },
    h6: {
      fontSize: "12px",
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            boxShadow: "none",
          },
          fontSize: "13px"
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontSize: "40px",
          fontWeight: 600,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: "10px"
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "10px"
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "40px"
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: "3.5px 14px"
        },
        root: {
          padding: "3.5px 14px !important"
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          padding: "0 !important"
        },
      },
    }
  },
});

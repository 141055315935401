import { createSlice } from '@reduxjs/toolkit';
import {
    logout
} from "../../src/Services/BackendAPI";

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        token: null,
        adminEmails: ['soniasharma@districtd.co'],
        userType: 'user',
        rolePermissions: [],
    },

    reducers: {
        saveAuthInfo: (state, param) => {
            const { payload } = param;
            state.user = payload.loginResult;
            state.token = payload.loginResult.token;
            state.rolePermissions = payload.permissions;
            if(!payload.loginResult.hasOwnProperty('roleId')) {
                state.userType = 'admin';
            }else{
                state.userType = 'user';
            }
        },
        logoutUser: (state) => {
            logout()
            localStorage.clear();
            state.user = null;
            state.token = null;
            state.rolePermissions = [];
            state.userType = 'user';
        },
    },
});

const { actions, reducer } = authSlice;

export const { saveAuthInfo, logoutUser } = actions;
export default reducer;

import { createSlice } from '@reduxjs/toolkit';
import { factorConfig } from '../Components/UIForClient/FactorPage/HeaderConfig';

const factorSlice = createSlice({
  name: 'factor',
  initialState: {
    factorData: {
      data: [],
      footData: [],
      totalData: []
    },
    cardData: {},
    ddData: {},
    headers: {
      columns: factorConfig.ScoreTableColumn,
      ratioHeader: factorConfig.ratioHeader,
      technicalHeader: factorConfig.technicalHeader,
    },
    title: {
      sectorAllocation: 'Sector Mix',
      capAllocation: 'Category Mix'
    }
  },

  reducers: {
    fetchFactorScore: (state, param) => {
      const { payload } = param;
      state.factorData = payload;
    },

    storeCardData: (state, param) => {
      const { payload } = param;
      state.cardData = payload;
    },

    storeDdData: (state, param) => {
      const { payload } = param;
      state.ddData = payload;
    }
  },
});

const { actions, reducer } = factorSlice;

export const { fetchFactorScore, storeCardData, storeDdData } = actions;
export default reducer;

const factorConfig = {
  ScoreTableColumn: [
    {
      field: "symbol",
      headerName: "Company",
      headerWeight: "150px",
    },
    {
      field: "weight",
      headerName: "Weight%",
      headerWeight: "150px",
    },
    {
      field: "growth",
      headerName: "Growth",
      type: "factor",
      color: "#588add",
      cardColor: "#eff4fe",
      headerWeight: "150px",
      info: "Assessing the company's past performance for sustained revenue & profitability growth.",
    },
    {
      field: "quality",
      headerName: "Quality",
      type: "factor",
      color: "#3a5c93",
      cardColor: "#cfe0fc",
      headerWeight: "150px",
      info: "Evaluating the overall robustness and reliability of the company's financial structure, management capabilities, operational efficiency & business moat.",
    },
    {
      field: "ssgr",
      headerName: "SSGR",
      type: "factor",
      color: "#273d62",
      cardColor: "#e5e5f3",
      headerWeight: "150px",
      info: "Estimating the company's ability to grow organically, independent of external financing, reflecting strong financial health and stability.",
    },
    {
      field: "value",
      headerName: "Value",
      type: "factor",
      color: "#000064",
      cardColor: "#b0ccfa",
      headerWeight: "150px",
      info: "Analysing the value of the stock in comparison to its peers and broader market segments.",
    },
    {
      field: "dividend",
      headerName: "Dividend",
      type: "factor",
      color: "#000064",
      cardColor: "#c0d6fb",
      headerWeight: "150px",
      info: "Examining company’s consistency of delivering good dividend yield with strong business fundamentals & scope of fundamental growth.",
    },
    {
      field: "relative_strength",
      headerName: "Relative Strength",
      type: "factor",
      color: "#123456",
      cardColor: "#e4e4f7",
      headerWeight: "150px",
      info: "Comparing the performance with relevant peers or benchmarks, highlighting its resilience and competitive positioning within the broader market context during various time intervals.",
    },
    {
      field: "momentum_sign",
      headerName: "Momentum Sign",
      type: "factor",
      color: "#00008F",
      cardColor: "#74b5f6",
      headerWeight: "150px",
      info: "Assessing the rate of change in the stock price over a defined period, indicating strength and direction of future trend.",
    },
  ],
  ratioHeader: [
    {
      field: "nse_ticker",
      headerName: "Company",
    },
    {
      field: "weight",
      headerName: "Weight%",
    },
    {
      field: "pe",
      headerName: "PE",
    },
    {
      field: "pb",
      headerName: "PB",
    },
    {
      field: "ev_ebidta",
      headerName: "EV/EBIDTA",
    },
    {
      field: "price_sale",
      headerName: "EV/Sales",
    },
    {
      field: "ebidtaMargin",
      headerName: "EBIDTA Margin",
    },
    {
      field: "patMargin",
      headerName: "PAT Margin",
    },
    {
      field: "roe",
      headerName: "ROE",
    },
  ],
  technicalHeader: [
    {
      field: "nse_ticker",
      headerName: "Company",
    },
    {
      field: "200DMA",
      headerName: "200DMA",
    },
    {
      field: "RSI",
      headerName: "RSI",
    },
    {
      field: "adv",
      headerName: "ADV",
    },
  ],
  sectorReturnHeader: [
    {
      field: "name",
      headerName: "Sector",
    },
    {
      field: "totalReturn",
      headerName: "Total Return (%)",
    },
  ],
  capReturnHeader: [
    {
      field: "name",
      headerName: "Mcap Category",
    },
    {
      field: "totalReturn",
      headerName: "Total Return (%)",
    },
  ],
  holdingHeader: [
    {
      field: "name",
      headerName: "Holding",
    },
    {
      field: "weightage",
      headerName: "Portfolio Weight",
    },
  ],
  gainerHeader: {
    one_month: [
      {
        field: "nse_ticker",
        headerName: "Stock",
      },
      {
        field: "one_month",
        headerName: "Total Return (%)",
      },
    ],
    three_month: [
      {
        field: "nse_ticker",
        headerName: "Stock",
      },
      {
        field: "three_month",
        headerName: "Total Return (%)",
      },
    ],
    six_month: [
      {
        field: "nse_ticker",
        headerName: "Stock",
      },
      {
        field: "six_month",
        headerName: "Total Return (%)",
      },
    ],
    one_year: [
      {
        field: "nse_ticker",
        headerName: "Stock",
      },
      {
        field: "one_year",
        headerName: "Total Return (%)",
      },
    ],
  },
  loserHeader: {
    one_month: [
      {
        field: "nse_ticker",
        headerName: "Stock",
      },
      {
        field: "one_month",
        headerName: "Total Return (%)",
      },
    ],
    three_month: [
      {
        field: "nse_ticker",
        headerName: "Stock",
      },
      {
        field: "three_month",
        headerName: "Total Return (%)",
      },
    ],
    six_month: [
      {
        field: "nse_ticker",
        headerName: "Stock",
      },
      {
        field: "six_month",
        headerName: "Total Return (%)",
      },
    ],
    one_year: [
      {
        field: "nse_ticker",
        headerName: "Stock",
      },
      {
        field: "one_year",
        headerName: "Total Return (%)",
      },
    ],
  },
  benchmarkHeader: {
    one_month: [
      {
        field: "name",
        headerName: "Name",
      },
      {
        field: "one_month",
        headerName: "Total Return (%)",
      },
    ],
    three_month: [
      {
        field: "name",
        headerName: "Name",
      },
      {
        field: "three_month",
        headerName: "Total Return (%)",
      },
    ],
    six_month: [
      {
        field: "name",
        headerName: "Name",
      },
      {
        field: "six_month",
        headerName: "Total Return (%)",
      },
    ],
    one_year: [
      {
        field: "name",
        headerName: "Name",
      },
      {
        field: "one_year",
        headerName: "Total Return (%)",
      },
    ],
  },
};

module.exports.factorConfig = factorConfig;

import axios from 'axios';

let baseURL = process.env.REACT_APP_BASE_URL;

// if (process.env.NODE_ENV === 'development') {
//   baseURL = 'http://localhost:3000';
// } else {
//   baseURL = 'https://orim.districtd.in/api';
// }
const api = axios.create({
  baseURL: baseURL, // Set your base URL here
  headers: {
    'Content-Type': 'application/json', // Set default headers if needed
  },
});

export default api;

import {
  orimRiskScore,
  orimdumperAPI,
  orimdumperAPITwo,
  orimdumperSignAPI,
  orimInvestmentDetailsTwo,
  fileUpload,
  orimUserPermission,
  orimFetchStrategiesAPI,
  saveStrategy,
} from "./request";

export function getRiskScoreAPI(body) {
  return orimdumperAPITwo({
    endpoint: `/calculations`,
    method: "POST",
    data: body,
    // headers:{}
  });
}

export function getSearchScript(payload) {
  return orimRiskScore({
    endpoint: `/security/search/${payload.symbol}/${payload.category}`,
    method: "GET",
    // data: payload,
    // headers:{}
  });
}

export function orimLogin(payload) {
  return orimdumperSignAPI({
    endpoint: `/login`,
    method: "POST",
    data: payload,
  });
}

export function orimAdminLogin(payload) {
  return orimdumperSignAPI({
    endpoint: `/admin-login`,
    method: "POST",
    data: payload,
  });
}

export function orimSignUp(payload) {
  return orimdumperSignAPI({
    endpoint: `/signup`,
    method: "POST",
    data: payload,
  });
}

export function orimLogout() {
  return orimdumperSignAPI({
    endpoint: `/orimdumper/api/v1/logout`,
    method: "GET",
    headers: {
      username: localStorage.getItem("username"),
      "auth-token": localStorage.getItem("token"),
    },
  });
}

export function logout() {
  return saveStrategy({
    endpoint: `/logout`,
    method: "POST",
  });
}

export function orimForgotPassword(payload) {
  return orimdumperSignAPI({
    endpoint: `/forgot-password`,
    method: "POST",
    data: payload,
  });
}

export function orimResetPassword(payload, resetToken) {
  return orimdumperSignAPI({
    endpoint: `/reset-password/${resetToken}`,
    method: "POST",
    data: payload,
  });
}

export function getUserPermissions(roleId) {
  return orimUserPermission({
    endpoint: `/permissions/${roleId}`,
    method: "GET",
    //  data: payload,
  });
}

export function riskScoreUpload(payload) {
  return orimRiskScore({
    endpoint: `/risk-score-file`,
    method: "POST",
    data: payload,
  });
}

export function getRiskScoreByID(body) {
  return orimdumperAPITwo({
    endpoint: `/orimdumper/api/v1/risk-profile-data`,
    method: "POST",
    data: body,
  });
}

export function getRecommendedAssets(payload) {
  const endpoint = payload ? `/fetch-strategies/${payload}` : '/fetch-strategies/';
  return saveStrategy({
    endpoint: endpoint,
    method: "GET",
    data: payload,
  });
}

export function investmentDetails(payload) {
  return orimInvestmentDetailsTwo({
    endpoint: `/orimdumper/api/v1/investment-details`,
    method: "POST",
    data: payload,
  });
}

export function getClientDetails() {
  return orimdumperAPI({
    endpoint: `/orimdumper/api/v1/client-details?page_no=1&user_type=ADMIN`,
    method: "GET",
  });
}

export function deleteInvestmentDetails(portfolioName) {
  return orimdumperAPITwo({
    endpoint: `/orimdumper/api/v1/delete-investment-details?portfolio_name=${portfolioName}`,
    method: "DELETE",
  });
}

export function updateInvestmentDetails(payload) {
  return orimdumperAPITwo({
    endpoint: `/orimdumper/api/v1/update-investment-details`,
    method: "PUT",
    data: payload,
  });
}

export function adminFileUpload(payload) {
  return fileUpload({
    endpoint: `/admin/file-upload`,
    method: "POST",
    data: payload,
  });
}

export function getRiskProfile(payload) {
  return orimdumperAPITwo({
    endpoint: `/get-risk-profile`,
    method: "POST",
    data: payload,
  });
}

export function getFactorScore(payload) {
  return orimdumperAPITwo({
    endpoint: `/get-factor-score`,
    method: "POST",
    data: payload,
  });
}

export function getFactorRatio(payload) {
  return orimdumperAPITwo({
    endpoint: `/get-factor-ratio`,
    method: "POST",
    data: payload,
  });
}

export function getCorporateAction(payload) {
  return orimdumperAPITwo({
    endpoint: `/get-corporate-action?fromDate=${payload.txnDate}`,
    method: "POST",
    data: payload,
  });
}

export function fetchSplitData(payload) {
  return saveStrategy({
    endpoint: `/get-split-data`,
    method: "POST",
    data: payload,
  });
}

export function fetchBenchMark() {
  return saveStrategy({
    endpoint: `/get-benchmark-prices`,
    method: "GET",
  });
}

export function getTransactionDate(payload) {
  return saveStrategy({
    endpoint: `/get-transaction-date`,
    method: "POST",
    data: payload,
  });
}

export function getInstrumentToken(payload) {
  return orimdumperAPITwo({
    endpoint: `/get-instrument-token`,
    method: "POST",
    data: payload,
  });
}

export function fetchStrategies() {
  return orimFetchStrategiesAPI({
    endpoint: `/fetch-strategies`,
    method: "GET",
  });
}

export function showRebalancePage(sid, product_id) {
  return saveStrategy({
    endpoint: `/strategy/${sid}/${product_id}`,
    method: "GET",
  });
}

export function saveDataToProductTbl(data, sid, product_id, description) {
  return orimFetchStrategiesAPI({
    endpoint: "/rebalancePortfolio",
    method: "POST",
    data: {
      data: data,
      sid: sid,
      product_id: product_id,
      description,
    },
  });
}

export function sendMailToClients(sid, pid) {
  return orimFetchStrategiesAPI({
    endpoint: "/rebalance-mail",
    method: "POST",
    data: {
      sid,
      pid,
    },
  });
}

export function saveStrategies(
  payload,
  headers = { "Content-Type": "application/json" }
) {
  return saveStrategy({
    endpoint: `/save-strategy`,
    method: "POST",
    data: payload,
    headers,
  });
}

export function ViewStrategy(payload) {
  return saveStrategy({
    endpoint: `/getAllStrategies`,
    method: "POST",
    data:payload
  });
}

export function StrategyStatusHandling(payload) {
  return saveStrategy({
    endpoint: `/changeStrategyStatus`,
    method: "POST",
    data: payload,
  });
}

export function editStrategy(
  payload,
  headers = { "Content-Type": "application/json" }
) {
  return saveStrategy({
    endpoint: `/edit-strategy`,
    method: "POST",
    data: payload,
    headers,
  });
}

export function getStrategyDetails(payload) {
  return saveStrategy({
    endpoint: `/client-strategy-details`,
    method: "POST",
    data: payload,
  });
}

export function saveUserData(payload) {
  return saveStrategy({
    endpoint: `/save-userData`,
    method: "POST",
    data: payload,
  });
}

export function verifyKYC(payload) {
  return saveStrategy({
    endpoint: `/verify-kyc`,
    method: "POST",
    data: payload,
  });
}

export function fetchPortfolio(payload) {
  return saveStrategy({
    endpoint: `/fetch-portfolio`,
    method: "POST",
    data: payload,
  });
}

export function sendAgreementOTPToClients(payload) {
  return saveStrategy({
    endpoint: `/agreement-otp`,
    method: "POST",
    data: payload,
  });
}
export function makePaymentAPI(payload) {
  return saveStrategy({
    endpoint: `/phonepe/payment`,
    method: "POST",
    data: payload,
  });
}

export function checkKycEntryExistence(payload) {
  return saveStrategy({
    endpoint: `/check-kyc-entry`,
    method: "POST",
    data: payload,
  });
}

export function clientStrategy(payload) {
  return saveStrategy({
    endpoint: `/clientstrategies`,
    method: "POST",
    data: payload,
  });
}

export function fetchPurchasedStrategies(payload) {
  return saveStrategy({
    endpoint: `/fetch-purchased-strategies`,
    method: "POST",
    data: payload,
  });
}

export function getRiskQuestionaire() {
  return saveStrategy({
    endpoint: `/get-risk-profile-questions`,
    method: "GET",
  });
}

export function checkClientRiskProfile(payload) {
  return saveStrategy({
    endpoint: `/get-risk-profile-status`,
    method: "POST",
    data: payload,
  });
}

export function saveQuestionaireResponseToDB(payload) {
  return saveStrategy({
    endpoint: `/save-questionaire-response`,
    method: "POST",
    data: payload,
  });
}
export function saveRiskQuestionaire(payload) {
  return saveStrategy({
    endpoint: `/save-risk-questionaire`,
    method: "POST",
    data: payload,
  });
}
export function QuestionaireUserData(payload){
  return saveStrategy({
    endpoint: `/save-questionaire-userdata`,
    method: "POST",
    data: payload,
  });
}
export function savePurchasedStrategiesToDb(payload) {
  return saveStrategy({
    endpoint: `/save-purchased-strategies`,
    method: "POST",
    data: payload,
  });
}

export function fetchStepNum(payload) {
  return saveStrategy({
    endpoint: `/fetch-step-no`,
    method: "POST",
    data: payload,
  });
}

export function ESignAgreement(payload) {
  return saveStrategy({
    endpoint: `/e-sign-api`,
    method: "POST",
    data: payload,
  });
}

export function callEsignAPI(payload) {
  return saveStrategy({
    endpoint: `/client-eSign`,
    method: "POST",
    data: payload,
  });
}

export function callBroker(payload) {
  return saveStrategy({
    endpoint: `/execute-trade`,
    method: "POST",
    data: payload,
  });
}

export function getHoldingsFromBroker(payload){
  return saveStrategy({
    endpoint: `/get-holdings-from-broker`,
    method: "POST",
    data: payload,
  });
}
export function saveZerodhaOrdersInRedis(payload){
  return saveStrategy({
    endpoint: `/save-zerodha-orders`,
    method: "POST",
    data: payload,
  });
}

export function getPortfolioDetails(payload) {
  return saveStrategy({
    endpoint: `/get-portfolio-details`,
    method: "POST",
    data: payload,
  });
}

export function upstoxOuthCallback(payload) {
  return saveStrategy({
    endpoint: `/upstox-oauth-callback`,
    method: "POST",
    data: payload,
  });
}

export function fyersOuthCallback(payload) {
  return saveStrategy({
    endpoint: `/fyers-oauth-callback`,
    method: "POST",
    data: payload,
  });
}
export function masterTrustOuthCallback(payload) {
  return saveStrategy({
    endpoint: `/masterT-oauth-callback`,
    method: "POST",
    data: payload,
  });
}

export function angelOneCallback(payload) {
  return saveStrategy({
    endpoint: `/angelone-callback`,
    method: "POST",
    data: payload,
  });
}
export function motilalCallback(payload) {
  return saveStrategy({
    endpoint: `/motilal-callback`,
    method: "POST",
    data: payload,
  });
}

export function zerodhaOrderDetails(payload) {
  return saveStrategy({
    endpoint: `/zerodha-oauth-prepareOrder`,
    method: "POST",
    data: payload,
  });
}

export function zerodhaCallback(payload) {
  return saveStrategy({
    endpoint: `/zerodha-oauth-callback`,
    method: "POST",
    data: payload,
  });
}

export function getClientsInfo(payload) {
  return saveStrategy({
    endpoint: `/getClients`,
    method: "POST",
    data: { distributor: payload },
  });
}
export function getRiskAnalyserUsers() {
  return saveStrategy({
    endpoint: `/get-riskAnalyser-users`,
    method: "GET",
  });
}

export function getClientMasterInfo() {
	return saveStrategy({
	  endpoint: `/get-client-master`,
	  method: "GET",
	});
  }

  export function downloadTransactions(data){
    return saveStrategy({
      endpoint:`/get-transaction-excel`,
      method:"POST",
      data:data,
    });
  }

  export function getAdminsInfo() {
    return saveStrategy({
      endpoint: `/get-admins-info`,
      method: "GET",
    });
    }

  export function setDistributorMaster(distributor, headers = { "Content-Type": "application/json" }) {
    return saveStrategy({
      endpoint: `/set-distributor-master`,
      method: "POST",
      data: distributor,
      headers,
    });
  }

  export function fetchDistributorMaster() {
    return saveStrategy({
      endpoint: `/fetch-distributor-master`,
      method: "GET",
    });
  }

  export function fetchDistributorLogo(Payload) {
    return saveStrategy({
      endpoint: `/fetch-distributor-logo/${Payload}`,
      method: "GET",
      data:Payload,
    });
  }

export function getStepNo(payload) {
  return saveStrategy({
    endpoint: `/fetch-step-no`,
    method: "POST",
    data: payload,
  });
}

export function saveTransaction(payload) {
  return saveStrategy({
    endpoint: `/save-transaction`,
    method: "POST",
    data: payload,
  });
}
export function portfolioRebalance(payload) {
  return saveStrategy({
    endpoint: `/rebalance-portfolio`,
    method: "POST",
    data: payload,
  });
}

export function authMiddleware(payload) {
  return saveStrategy({
    endpoint: `/authenticate`,
    method: "POST",
    data: payload,
  });
}

export function fetchHoldings(payload) {
  return saveStrategy({
    endpoint: `/fetch-holdings`,
    method: "POST",
    data: payload,
  });
}

export function postESign(payload) {
  return saveStrategy({
    endpoint: `/post-e-sign`,
    method: "POST",
    data: payload,
  });
}

export function fetchOldAndNewPortfolio(payload) {
  return saveStrategy({
    endpoint: `/fetch-old-new-portfolio`,
    method: "POST",
    data: payload,
  });
}

export function checkTradeExecution(payload) {
  return saveStrategy({
    endpoint: `/check-trade-execution`,
    method: "POST",
    data: payload,
  });
}
export function saveCoupons(payload) {
  return saveStrategy({
    endpoint: `/save-coupons`,
    method: "POST",
    data: payload,
  });
}

export function getCoupons() {
  return saveStrategy({
    endpoint: `/get-coupons`,
    method: "GET",
  });
}

export function validateCoupon(payload) {
  return saveStrategy({
    endpoint: `/validate-coupons`,
    method: "POST",
    data: payload,
  });
}

export function fetchClientPortfolio(payload) {
  return saveStrategy({
    endpoint: `/fetch-client-portfolio`,
    method: "POST",
    data: payload,
  });
}

export function deleteCoupon(payload) {
  return saveStrategy({
    endpoint: `/delete-coupon`,
    method: "POST",
    data: payload,
  });
}

export function changeStatus(payload) {
  return saveStrategy({
    endpoint: `/update-coupon-status`,
    method: "POST",
    data: payload,
  });
}

export function getMailLogs(){
  return saveStrategy({
    endpoint:`/get-maillogs`,
    method:"GET",
    
  })
}
export function getUpstoxOrderDetail() {
  return saveStrategy({
    endpoint: `/upstox/post-order-place`,
    method: "POST",

  })
}
export function saveUpstoxOrderInRedis() {
  return saveStrategy({
    endpoint: `/save-upstox-details-in-redis`,
    method: "GET"
  })
}
export function setStepJump(payload) {
  return saveStrategy({
    endpoint: `/set-step-jump`,
    method: "POST",
    data: payload,
  });
}


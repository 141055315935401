import { createSlice } from '@reduxjs/toolkit';

const securitySlice = createSlice({
    name: 'security',
    initialState: {
        riskScore: {
            portfolioAnalyser: {}
        },
        portfolioDetails: [],
        recommendedAssets: [],
        openSecurityDetailsModal: false,
        clientDetailsList: [],
        rolePermissions: [],
        enableAsset: false
    },

    reducers: {
        fetchRecommendedAssetsAction: (state, param) => {
            const { payload } = param;
            state.recommendedAssets = payload;
        },

        storeSecurity: (state, param) => {
            const { payload } = param;
            state.portfolioDetails = payload;
        }
    },
});

const { actions, reducer } = securitySlice;

export const { fetchRecommendedAssetsAction, storeSecurity } = actions;
export default reducer;
